import { Button, Card, Container, Image, Modal, Stack } from "react-bootstrap";
import ExtractIcon from "../assets/images/extract-icon.png";
import DiscountIcon from "../assets/images/discount-icon.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Suspense, useContext, useState } from "react";
import { AppContext } from "../providers/AppProvider";
import { WhatsappBanner } from "../components/Images";

export function Component() {
  const navigate = useNavigate();
  const location = useLocation();
  const app = useContext(AppContext);

  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  if (!app.isAuthenticated) {
    return <Navigate to="/login" state={{ location }} replace />;
  }

  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section>
        <Container>
          <h1 className="text-center mb-5">MEU EXTRATO</h1>

          <Stack direction="horizontal" gap={5} className="flex-column flex-lg-row align-items-center justify-content-center">
            <Card className="extract-card" onClick={() => navigate('/meus-numeros')}>
              <Card.Body className="d-flex flex-column align-items-center">
                <Image className="extract-card__image" src={ExtractIcon} alt="" />
                <h2 className="extract-card__title">Meus números</h2>
              </Card.Body>
            </Card>

            <Card className="extract-card" onClick={() => navigate('/meus-descontos')}>
              <Card.Body className="d-flex flex-column align-items-center">
                <Image className="extract-card__image" src={DiscountIcon} alt="" />
                <h2 className="extract-card__title">Meus descontos</h2>
              </Card.Body>
            </Card>
          </Stack>
        </Container>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="d-flex flex-column">
          <Image src={WhatsappBanner} alt="Banner - Novo canal de Whatsapp" fluid />
          <Button variant="primary" onClick={handleClose} className="mt-3 mx-auto button">Fechar</Button>
        </Modal.Body>
      </Modal>
    </Suspense>
  )
}